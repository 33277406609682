import React from "react"
import Image from "next/image";
import styles from "./hoverImage.module.scss"

const HoverImage: React.FC<{
    src?: string,
    css?: React.CSSProperties,
    className?: string
}> = ({ src, css = {}, className = "" }) => {
    if(!src) return null;

    return (
        <div className={`${styles.cover} ${className}`}>
            <Image
                src={src}
                alt="hover image"
                layout="fill"
                loading="lazy"
                style={css}
            />
        </div>
    )
}

export default HoverImage;