import cls from "./builder.module.scss";
import {VideoBackground} from "./VideoBackground";
import React, {useRef, CSSProperties, FC, Fragment} from "react";
import useIsInView from "../../hooks/useInView";
import {builderDataType} from "./types";

interface IColumn {
    animation: string;
    columnClassname: string;
    columnStyles: CSSProperties;
    videoSource: string;
    childIdArray: builderDataType['idArray'][number]['childIdArray'];
    showOneComponentChild: any;
    BuilderJSX: ({dataKey}: any) => React.JSX.Element
}

const Column: FC<IColumn> = (
    {
        animation,
        columnClassname,
        columnStyles,
        videoSource,
        childIdArray,
        showOneComponentChild,
        BuilderJSX
    }
) => {
    const columnRef = useRef<HTMLDivElement>(null);
    const inView = !!animation ? useIsInView(columnRef) : true
    const ChildComponentShow = () => {
        if (showOneComponentChild) return <BuilderJSX dataKey={showOneComponentChild}/>
        return <Fragment>
            {childIdArray?.map((elInEl, indElInEl: number) => (
                <div key={indElInEl} className={`${cls.builder_component} ${cls[elInEl.hoverAnimation]}`}>
                    <BuilderJSX dataKey={elInEl}/>
                </div>
            ))}
        </Fragment>
    }
    return (
        <div
            ref={columnRef}
            data-aos={animation}
            className={`${columnClassname} ${!inView ? "dataAosRef" : ""}`}
            style={{...columnStyles, position: 'relative'}}
        >
            <VideoBackground src={videoSource}/>
            <ChildComponentShow/>
        </div>
    )
};

export default Column;
