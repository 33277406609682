import cls from './videoBackground.module.scss'

export const VideoBackground: React.FC<{ src: string | null }> = ({ src }) => {
    if (!src) return null
    return (
        <div className={cls.backVideoBlock}>
            <iframe
                className={cls.videoFrame}
                src={src}
                width="100%"
                height='100%'
            />
        </div>
    )
}
