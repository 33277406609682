import React, {FC} from 'react'
import cls from "../builder_carousel.module.scss";
import HoverImage from '../../HoverImage';

interface IProps {
    card: Record<string, string>,
}

const BrandListCard: FC<IProps> = ({card}) => {
    const {
        image_path = "",
        linkValue = "",
        targeting = "",
        hover_path = ""
    } = card

    return <div className={cls.carousel_card_wrapper}>
        <a href={linkValue} target={targeting} rel="noreferrer">
            <div className={cls.carousel_card}>
                <div className={cls.carousel_card_image_wrapper}>
                    <img
                        alt="carousel card image"
                        aria-hidden="true"
                        src={image_path}
                    />
                    <HoverImage
                        src={hover_path}
                        className={cls.carousel_card_hover}
                    />
                </div>
            </div>
        </a>
    </div>
}

export default BrandListCard